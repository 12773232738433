import { useSiteStore } from '~/stores/site.store';

export default function (mobileNumber: string): string {
  const site = useSiteStore();
  try {
    const dialingCode = site.getDialingCode.replace('+', '');
    const zeroStartPattern: RegExp = /^0/;
    const digitStartPattern: RegExp = /^[1-9]/;

    if (mobileNumber.startsWith(dialingCode)) return mobileNumber;

    return zeroStartPattern.test(mobileNumber)
      ? mobileNumber.replace(zeroStartPattern, dialingCode)
      : digitStartPattern.test(mobileNumber)
        ? `${dialingCode}${mobileNumber}`
        : mobileNumber;
  } catch (e) {
    console.error(e);
  }
}
