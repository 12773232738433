<script setup lang="ts">
import { MenuAlt2Icon } from '@heroicons/vue/outline/index.js';
import UserCashBalance from '~/components/user/user-cash-balance.vue';
import Button from '~/components/design-system/button.vue';
import List from '~/components/design-system/list/index.vue';
import ListItem from '~/components/design-system/list/list-item.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { trackingMethods } from '~~/plugins/analytics.client';
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import { MoonIcon, SunIcon } from '@heroicons/vue/outline/index';
import { useAccountOptions } from '~/composables/useAccountOptions';

const { $walletService } = useServices();
const auth = useAuthStore();
const settings = useSiteStore();
const hamburgerActive: globalThis.Ref<boolean> = ref(false);
const router = useRouter();
const accountNavBarMenu = computed(() =>
  useAccountOptions().filter((uao) => uao.includeInHamburger && uao.active)
);

const colorMode = useColorMode();
interface ILanguage {
  cultureCode: string;
  default: string;
  languageCode: string;
  translation: string;
}

let selectedLanguage: string = 'Select a language';
let languages: Array<ILanguage> = [];
let isDisabled: boolean = false;
const activeRoute: globalThis.Ref<string> = ref('');

const refreshBalances = async () => {
  await $walletService.fetchBalances();
};

onMounted(() => {
  const siteSettings = settings.getConfig;
  languages = siteSettings?.cultures;
  if (languages?.length == 1) {
    selectedLanguage = languages[0]['translation'];
    isDisabled = true;
  }
  activeRoute.value = router.currentRoute.value.path;
});

watchEffect(() => {
  activeRoute.value = router.currentRoute.value.path;
  if (hamburgerActive.value === true) {
    // send GA tracking method when Hamburger menu opened
    trackingMethods.toggleHamburgerMenu();
  }
});

//prevent body scrolling whilst hamburger active
watch(
  () => hamburgerActive.value,
  (hamburgNew, hamburgOld) => {
    hamburgNew ? lockBodyScroll() : unlockBodyScroll();
    if (hamburgNew) refreshBalances();
  }
);
</script>

<template>
  <div>
    <MenuAlt2Icon
      class="w-6 mr-1 xs:mr-2 md:mr-3 cursor-pointer"
      @click="hamburgerActive = !hamburgerActive"
    />
    <Sidebar
      v-model:visible="hamburgerActive"
      class="dark:bg-dark-800 bg-light-50 b-1 b-r-1 dark:b-dark-500 b-light-500 w-80"
    >
      <template #header>
        <div
          class="flex justify-content-between px-2 bg-light-50 dark:bg-dark-800 p-3 w-full"
        >
          <div v-if="!auth.isLoggedIn" class="flex">
            <Button
              class="text-white !mx-1 uppercase"
              group="secondary"
              size="medium"
              @click="
                [
                  settings.activateModal('login'),
                  (hamburgerActive = !hamburgerActive),
                  unlockBodyScroll(),
                ]
              "
            >
              {{ $t('login') }}
            </Button>
            <Button
              class="text-white !mx-1 ml-1 uppercase"
              size="medium"
              @click="
                [
                  settings.activateModal('register'),
                  (hamburgerActive = !hamburgerActive),
                  unlockBodyScroll(),
                ]
              "
            >
              {{ $t('jpc-register') }}
            </Button>
          </div>
          <div v-else class="w-full">
            <div class="text-dark-700 dark:text-white text-xs line-height-3">
              <div class="flex justify-content-between w-full">
                <p class="mt-0 mb-2">
                  {{ $t('welcome') }},
                  <!-- prettier-ignore -->
                  <br>
                  <strong>
                    <!-- If first and last name exist display else display fallback -->
                    {{
                      auth.currentUser?.firstname && auth.currentUser?.lastname
                        ? auth.currentUser?.firstname +
                          ' ' +
                          auth.currentUser?.lastname
                        : $t('user')
                    }}
                  </strong>
                  <br />
                </p>

                <div>
                  <strong>
                    {{ $t('account-number') }}:
                    {{ auth.currentUser?.primaryMobileNumber }}
                  </strong>
                </div>
                <Button
                  class="icon-btn--bg mr-6"
                  group="tertiary"
                  size="icon-md"
                  @click="toggleDark()"
                >
                  <component
                    :is="colorMode.preference === 'dark' ? SunIcon : MoonIcon"
                    class="icon-svg icon-btn--bg"
                  />
                </Button>
              </div>
            </div>
            <UserCashBalance :type="'hamburger'" />
          </div>
        </div>
      </template>

      <div>
        <Accordion
          :activeIndex="0"
          :multiple="false"
          class="hamburger-accordion"
        >
          <AccordionTab
            v-if="auth.isLoggedIn"
            :header="$t('my-account')"
            headerClass="dark:bg-dark-800 bg-light-50 dark:text-white text-dark-700 b-0 dark:b-dark-500 b-light-500 box-shadow right-arrow"
            contentClass="bg-light-200 dark:bg-dark-900 b-0 dark:b-dark-500 b-light-500"
            header-style="box-shadow"
          >
            <List>
              <ListItem class="sidebar-item-container">
                <Button
                  type="button"
                  class="px-3 m-1 w-full ml-0 bg-light-50 dark:bg-dark-800 text-dark-700 dark:text-white text-sm"
                  :group="
                    activeRoute === `${item.Path}` ? 'primary' : 'secondary'
                  "
                  v-for="item in accountNavBarMenu"
                  :key="item.id"
                  @click="
                    [
                      auth.setAccountModalPage(item.id),
                      (hamburgerActive = !hamburgerActive),
                      unlockBodyScroll(),
                    ]
                  "
                >
                  {{ $t(item.key) }}
                </Button>
              </ListItem>
            </List>
          </AccordionTab>

          <AccordionTab
            :header="$t('quick-links')"
            headerClass="border-1 dark:bg-dark-800 bg-light-50 dark:text-white text-dark-700 b-0 right-arrow"
            contentClass="bg-light-200 dark:bg-dark-900 b-0  dark:b-dark-500 b-light-500"
          >
            <List>
              <ListItem class="sidebar-item-container">
                <Button
                  v-for="item in settings.getNav({ nav: 'left' })"
                  :key="item.Alias"
                  type="button"
                  :group="
                    activeRoute === `${item.Path}` ? 'primary' : 'secondary'
                  "
                  class="px-3 m-1 w-full ml-0 bg-light-50 dark:bg-dark-800 text-dark-700 dark:text-white text-sm"
                  @click="
                    [
                      router.push(item.Path),
                      (hamburgerActive = !hamburgerActive),
                    ]
                  "
                >
                  {{ $t(item.Alias) }}
                </Button>
              </ListItem>
            </List>
          </AccordionTab>
        </Accordion>
      </div>
      <div
        class="flex flex-column py-2 b-0 b-top-1 dark:b-dark-500 b-light-500 box-shadow-apps"
      >
        <!--        <AppsDownload-->
        <!--          Type="NavBar"-->
        <!--          class="p-bottom-6 static"-->
        <!--          :background="'dark:bg-dark-900'"-->
        <!--        />-->

        <div v-if="languages?.length > 1" class="ml-2 mr-2 mb-3">
          <Dropdown
            :options="languages"
            optionLabel="translation"
            :placeholder="selectedLanguage"
            :disabled="isDisabled"
            option-value="cultureCode"
            :model-value="$locale.locale"
            @change="$locale.changeLocale($event.value)"
            class="justify-content-center align-content-center"
          />
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<style lang="scss" scoped>
@media only screen and (min-device-height: 200px) and (max-device-height: 740px) and (orientation: portrait) {
  .sidebar-item-container {
    max-height: 22rem !important;
    display: grid !important;
    overflow-y: scroll !important;
  }
}

@media only screen and (min-device-height: 200px) and (max-device-height: 600px) and (orientation: landscape) {
  .sidebar-item-container {
    max-height: 19rem !important;
    display: grid !important;
    overflow-y: scroll !important;
  }
}
</style>

<style lang="scss">
.hamburger-accordion.p-accordion .p-accordion-content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.p-accordion-content .link-active {
  color: red !important;
}

.hamburger-accordion .p-accordion-header-link.p-accordion-header-action {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
}

.box-shadow-apps {
  box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  .dark & {
    background-color: #1d2129;
  }
}

.p-sidebar .p-sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eceff6;
  .dark & {
    background-color: #121417;
  }
}

// close button SideBar
.p-sidebar .p-sidebar-header .p-sidebar-close {
  position: absolute;
  right: 18px;
  top: 16px;
  border-radius: 6px;
}

.p-sidebar-close-icon {
  background: #eceff6;
  color: #2b303b;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  background-color: #eceff6;
}

.p-sidebar .p-sidebar-header button.p-sidebar-close.p-sidebar-icon.p-link {
  box-shadow: none;
}

.right-arrow > a {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.dark {
  .p-sidebar-close-icon {
    background: #121417;
    color: #fff;
  }

  .hamburger-accordion .p-accordion-tab .p-accordion-content {
    background-color: #1d2129;
    border-color: #464f60;
    border-width: 0px;
  }

  .hamburger-accordion .p-accordion-header-link.p-accordion-header-action {
    box-shadow: 0 6px 12px 0 #000;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon,
  button.p-sidebar-close.p-sidebar-icon.p-link:hover {
    background: #121417;
  }
}
</style>
