<script setup lang="ts">
const Icon = useIcon();
const props = defineProps(['IconClass']);
import { OfflineIcon } from 'assets/data/icons';
</script>
<template>
  <Icon
    :path="OfflineIcon"
    filled
    width="180"
    height="180"
    viewBox="0 0 180 180"
    :class="IconClass"
  />
</template>
