<script setup lang="ts">
import { OfflineIcon } from '~/components/design-system/icons';
const { $t } = useNuxtApp();
interface IOfflineProps {
  inModal?: boolean;
}

const props = withDefaults(defineProps<IOfflineProps>(), {
  inModal: false,
});
</script>

<template>
  <div>
    <div class="container p-4">
      <div class="w-full" :style="{ minHeight: inModal ? 'auto' : '80dvh' }">
        <div
          class="mx-auto dark:text-white mb-4"
          :class="inModal ? 'h-10rem' : 'h-10-rem md:h-18rem'"
        >
          <OfflineIcon />
        </div>
        <div class="w-full md:w-1/2">
          <div
            class="text-center font-bold dark:text-white"
            :class="inModal ? 'text-xl' : 'text-xl md:text-4xl'"
          >
            {{ $t('offline-heading') }}
          </div>
          <div
            class="text-center dark:text-white mt-2"
            :class="inModal ? 'text-xl' : 'text-xl md:text-4xl'"
          >
            {{ $t('offline-subheading') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
