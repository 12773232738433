<script setup lang="ts">
interface IListProps {
  class?: string;
}
const props = withDefaults(defineProps<IListProps>(), {
  class: '',
});
</script>
<template>
  <div class="list w-full" :class="props.class">
    <slot />
  </div>
</template>
