import {
  LazyAuthPostReg01VerifyName,
  LazyAuthPostReg02CardWelcomeOffer,
  LazyAuthPostReg03SecurityQuestion,
  LazyAuthPostReg04PartialCompletion,
  LazyAuthPostReg05FullCompletion,
  LazyUserInterfaceFiltersFilterFields,
  LazyUserInterfaceSearch,
  LazyUserInterfaceShareModal,
  LazyUserPasswordResetEnterOtp,
  LazyUserPasswordResetOtpOption,
  LazyUserPasswordResetSuccess,
  LazyAccountTellerWithdrawalAlert,
  LazyAccountLock,
  AuthLogin,
  AuthRegistrationV2,
  CategoryGrid,
  UserInterfaceOfflineNotice,
  AuthRegistrationV3,
  AuthLoginV2,
  LazyAccountHistoryAssignBonusModal,
} from '#components';
import {
  BigCityWheelIcon,
  ExclamationIcon,
  LockIcon,
} from '~/components/design-system/icons/index';
import { InformationCircleIcon } from '@heroicons/vue/outline/index.js';
import { uuid } from '~/utilities/uuid';
import type { Modals } from '~/interfaces/dto/general/modals';
import { useGameStore } from '~/stores/games.store';

interface IModalContextBindings {
  titleKey: string;
  key: string;
  breakpoints?: Record<string, string>;
  width?: string | number;
  component?: any;
  class?: string;
  closeModalOption?: boolean;
  headerIcon?: any;
  headerIconClass?: string;
  iconTooltip?: string;
}
export default function (
  currentModal: Modals | undefined | string | null
): IModalContextBindings {
  const game = useGameStore();
  const bindings: IModalContextBindings = {
    titleKey: '',
    key: uuid(),
  };
  switch (currentModal) {
    case 'search':
      Object.assign(bindings, {
        titleKey: 'search-games',
        component: LazyUserInterfaceSearch,
      });
      break;
    case 'passwordResetStepOne':
      Object.assign(bindings, {
        titleKey: 'fogot-password-title',
        component: LazyUserPasswordResetOtpOption,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'passwordResetStepTwo':
      Object.assign(bindings, {
        titleKey: 'fogot-password-title',
        component: LazyUserPasswordResetEnterOtp,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'passwordResetStepSuccess':
      Object.assign(bindings, {
        titleKey: 'fogot-password-title',
        component: LazyUserPasswordResetSuccess,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationVerifyName':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: LazyAuthPostReg01VerifyName,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationWelcomeOffer':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: LazyAuthPostReg02CardWelcomeOffer,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationSecurityQuestions':
      Object.assign(bindings, {
        titleKey: 'offer-and-acc-security',
        component: LazyAuthPostReg03SecurityQuestion,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationPartialCompletion':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: LazyAuthPostReg04PartialCompletion,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: false,
      });
      break;
    case 'registrationFullCompletion':
      Object.assign(bindings, {
        titleKey: 'welcome-to-jpc',
        component: LazyAuthPostReg05FullCompletion,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'register':
      Object.assign(bindings, {
        titleKey: 'jpc-register',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: AuthRegistrationV3,
      });
      break;
    case 'login':
      Object.assign(bindings, {
        titleKey: 'login',
        component: AuthLoginV2,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: true,
      });
      break;
    case 'gameShare':
      Object.assign(bindings, {
        titleKey: 'share',
        component: LazyUserInterfaceShareModal,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
    case 'filters':
      Object.assign(bindings, {
        titleKey: 'filter-by',
        component: LazyUserInterfaceFiltersFilterFields,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        class: 'flex flex-row flex-wrap align-items-center',
        vertical: true,
      });
      break;
    case 'category':
      Object.assign(bindings, {
        titleKey: `vertical.category.${game.returnCurrentCategory}`,
        component: CategoryGrid,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        class: 'flex flex-row flex-wrap align-items-center p-2',
        category: game.returnCurrentCategory,
        inModal: true,
      });
      break;
    case 'withdrawalAlert':
      Object.assign(bindings, {
        titleKey: 'withdrawal-alert',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: LazyAccountTellerWithdrawalAlert,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-primary-gold-500 w-6 h-6',
        iconTooltip: 'bonus-wallet-warning-tooltip',
      });
      break;
    case 'accountLock':
      Object.assign(bindings, {
        titleKey: 'account-locked',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: LazyAccountLock,
        headerIcon: ExclamationIcon,
        headerIconClass: 'text-primary-gold-500 w-6 h-6',
        iconTooltip: 'account-lock-tooltip',
      });
      break;
    case 'old-reg':
      Object.assign(bindings, {
        titleKey: 'jpc-register',
        component: AuthRegistrationV2,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
      });
      break;
      case 'old-login':
      Object.assign(bindings, {
        titleKey: 'login',
        component: AuthLogin,
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        closeModalOption: true,
      });
      break;
    case 'offline':
      Object.assign(bindings, {
        titleKey: 'offline',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: UserInterfaceOfflineNotice,
        closeModalOption: false,
        inModal: true,
      });
      break;
      case 'transactionAssignBonus':
      Object.assign(bindings, {
        titleKey: 'jpc-assign-bonus-header',
        width: '420px',
        breakpoints: { '425px': '326px', '360px': '320px' },
        component: LazyAccountHistoryAssignBonusModal,
        headerIcon: InformationCircleIcon,
        headerIconClass: 'text-primary-gold-500 w-6 h-6',
      });
      break;
  }

  return bindings;
}
