<script setup lang="ts">
const props = defineProps();
</script>

<template>
  <div class="relative">
    <LazyDesignSystemButton
      class="z-10 py-3 dark:text-white"
      group="link"
      v-bind="{ ...props, ...$attrs }"
    >
      <slot />
    </LazyDesignSystemButton>
    <div class="glow"></div>
    <div class="particles">
      <div class="rotate">
        <div v-for="n in 12" :key="n" class="angle">
          <div class="size">
            <div class="position">
              <div class="pulse">
                <div class="particle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes animate-reverse {
  0% {
    transform: rotate(360deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes bg {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 10000px;
  }
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }

  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}

.glow {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  animation: glow 3s linear 0s infinite alternate;
}

.particles {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
}

.rotate {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
}

.angle {
  position: absolute;
  top: 0;
  left: 0;
}

.size {
  position: absolute;
  top: 0;
  left: 0;
}

.position {
  position: absolute;
  top: 0;
  left: 0;
}

.pulse {
  position: absolute;
  top: 0;
  left: 0;
}

.particle {
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.rotate {
  animation: rotate 20s linear 0s infinite alternate;
}

.pulse {
  animation: pulse 1.5s linear 0s infinite alternate;
}

@keyframes glow {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 0 30px 15px #fff, 25px 15px 50px 10px #0068ff,
      -5px -25px 30px 5px #d839ff;
  }

  100% {
    transform: rotate(15deg);
    box-shadow: 0 0 45px 15px #d839ff, 35px 20px 40px 10px #fff,
      -30px -30px 40px 5px #0068ff;
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes angle {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes size {
  0% {
    -webkit-transform: scale(0.2);
  }

  100% {
    -webkit-transform: scale(0.6);
  }
}

@keyframes position {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translate3d(100px, 100px, 0);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.5);
  }
}

@keyframes particle {
  0% {
    box-shadow: inset 0 0 20px 20px #d839ff, 0 0 50px 10px #d839ff,
      inset 0 0 80px 80px #ffffff;
  }

  33.33% {
    box-shadow: inset 0 0 20px 20px #d839ff, 0 0 130px 10px #d839ff,
      inset 0 0 50px 50px #ffffff;
  }

  33.34% {
    box-shadow: inset 0 0 20px 20px #0068ff, 0 0 50px 10px #0068ff,
      inset 0 0 80px 80px #d036f6;
  }

  66.66% {
    box-shadow: inset 0 0 20px 20px #0068ff, 0 0 130px 10px #0068ff,
      inset 0 0 50px 50px #d036f6;
  }

  66.67% {
    box-shadow: inset 0 0 20px 20px #b32fd4, 0 0 50px 10px #b32fd4,
      inset 0 0 80px 80px #9727b3;
  }

  100% {
    box-shadow: inset 0 0 20px 20px #b32fd4, 0 0 130px 10px #b32fd4,
      inset 0 0 50px 50px #9727b3;
  }
}

.rotate .angle:nth-child(1) {
  /* change the angle every 2 seconds */
  animation: angle 10s steps(5) 0s infinite;
}

.rotate .angle:nth-child(1) .size {
  /* change the size of the particle every 2 seconds */
  animation: size 10s steps(5) 0s infinite;
}

.rotate .angle:nth-child(1) .particle {
  /* animate the glow and change the color every 2 seconds */
  animation: particle 6s linear infinite alternate;
}

.rotate .angle:nth-child(1) .position {
  /* animate the fly out of the particle and its fade out at the end */
  animation: position 2s linear 0s infinite;
}

.rotate .angle:nth-child(2) {
  /* change the angle every 2 seconds */
  animation: angle 4.95s steps(3) -1.65s infinite;
}

.rotate .angle:nth-child(2) .size {
  /* change the size of the particle every 2 seconds */
  animation: size 4.95s steps(3) -1.65s infinite alternate;
}

.rotate .angle:nth-child(2) .particle {
  /* animate the glow and change the color every 2 seconds */
  animation: particle 4.95s linear -3.3s infinite alternate;
}

.rotate .angle:nth-child(2) .position {
  /* animate the fly out of the particle and its fade out at the end */
  animation: position 1.65s linear 0s infinite;
}

.rotate .angle:nth-child(3) {
  /* change the angle every 2 seconds */
  animation: angle 13.76s steps(8) -6.88s infinite;
}

.rotate .angle:nth-child(3) .size {
  /* change the size of the particle every 2 seconds */
  animation: size 6.88s steps(4) -5.16s infinite alternate;
}

.rotate .angle:nth-child(3) .particle {
  /* animate the glow and change the color every 2 seconds */
  animation: particle 5.16s linear -1.72s infinite alternate;
}

.rotate .angle:nth-child(3) .position {
  /* animate the fly out of the particle and its fade out at the end */
  animation: position 1.72s linear 0s infinite;
}

.rotate .angle:nth-child(5) .position {
  /* animate the fly out of the particle and its fade out at the end */
  animation: position 2.45s linear 0s infinite;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>
