<script setup lang="ts">
import UserMenuOptions from '~/components/user/user-menu-options.vue';
import { useAuthStore } from '~/stores/auth.store';
const router = useRouter();
const auth = useAuthStore();
onBeforeMount(() => {
  if (!auth.loggedIn) router.push('/');
});
</script>
<template>
  <div
    v-if="auth.loggedIn"
    class="flex flex-column md:flex-row md:min-h-142 relative"
  >
    <div
      class="w-full md:w-64 lg:w-72 bg-light-200 dark:bg-dark-700 px-2 pt-2 md:p-0 z-50 sticky top-0 b-light-400 dark:b-dark-500 border-none sm:border-right-1"
    >
      <UserMenuOptions />
    </div>
    <div class="w-full">
      <slot />
    </div>
  </div>
</template>
