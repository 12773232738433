<script setup>
import { templateFieldControl } from 'assets/data/templateFieldControl';
import { ArrayToObject, mergeSort } from '~/utilities/data-transformers';
import Button from '~/components/design-system/button.vue';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import GenericError from '~/components/user-interface/generic-error.vue';
import { useAuthStore } from '~/stores/auth.store';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { trackingMethods } from '~/plugins/analytics.client';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
const { $t } = useNuxtApp();
const {
  $registrationService,
  $authService,
  $complianceService,
  $storageService,
} = useServices();
const authStore = useAuthStore();

const prepareMobileNumber = usePrepareMobileNumber;

const auth = useAuthStore();
const site = useSiteStore();

const template = ref([]);
const labels = ref({});
const fields = ref([]);

const formError = ref(null);
const formLoading = ref(false);

const model = ref({});
const modelErrors = ref({});
const modelValidity = ref({});

const activePage = ref(0);

const bdayLock = ref(false);
const dobRegex =
  '^([0-9]){2}(([0][2]([0][1-9]|[1][0-9]|[2][0-8]))|((([0][469])|([1][1]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][0])))|((([0][13578])|([1][02]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][01]))))([0-9]*)$';
const leapYearDobRegex =
  '^([0-9]){2}(([0][2]([0][1-9]|[1][0-9]|[2][0-9]))|((([0][469])|([1][1]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][0])))|((([0][13578])|([1][02]))(([0][1-9])|([1][0-9])|([2][0-9])|([3][01]))))([0-9]*)$';

const ignoredFields = 2; //ignoring marketing checkbox and idNumberType (already set)

const calendarIcon = computed(() => {
  return bdayLock.value ? 'pi pi-lock' : 'pi pi-calendar';
});

// this is purely for validating and submission
const dateOfBirth = ref();
const pages = ref(0);
const progressBar = computed(() => {
  const keys = Object.keys(modelValidity.value);
  return (keys.length / (fields.value.length - ignoredFields)) * 100;
});

const isFirstPage = computed(() => activePage.value === 0);
const isLastPage = computed(() => activePage.value + 1 === pages.value);

const isPageValid = computed(() => {
  if (!template.value.length) return false;

  const errorKeys = Object.keys(modelErrors.value);

  const validKeys = Object.keys(modelValidity.value);
  let currentPageSize =
    template?.value[activePage.value]?.templateFields?.length || 0;

  if (isLastPage.value) {
    currentPageSize = currentPageSize - 2; //removing optional receive promotional and id type (preset) from count
  }
  const errorsForPage = errorKeys.some((ek) => {
    return modelErrors.value[ek]['page'] === activePage.value + 1;
  });

  const errorCountPerPage = errorKeys.filter((ek) => {
    return modelErrors.value[ek]['page'] === activePage.value + 1;
  });

  const validityForPage = validKeys.some((vk) => {
    return modelValidity.value[vk]['page'] === activePage.value + 1;
  });

  const validKeysForPage = validKeys.filter((vk) => {
    return modelValidity.value[vk]['page'] === activePage.value + 1;
  });

  if (errorCountPerPage.length + validKeysForPage.length !== currentPageSize) {
    return false;
  }

  return validKeysForPage.length === currentPageSize || !errorsForPage;
});

//Map Kentico strings
function translateItems(items) {
  if (!items) return;
  return items.map((item) => {
    return {
      key: $t(`jpc-reg-${item.replaceAll(' ', '-')}`),
      value: item.replaceAll(' ', '').replaceAll('-', ''),
    };
  });
}

onMounted(async () => {
  const { templateSections } = await $registrationService.fetchTemplate();

  // set number of pages
  pages.value = templateSections?.length;
  // instantiate template
  template.value = templateSections;
  // flatten the arrays
  fields.value = templateSections.reduce((acc, curr, index) => {
    const fields = curr.templateFields;
    fields.forEach((field) => (field['page'] = index + 1));
    return [...acc, ...fields];
  }, []);
  // build the model
  const modelObject = ArrayToObject({
    arr: fields.value,
    property: 'propertyName',
  });

  const labelObject = ArrayToObject({
    arr: fields.value,
    property: 'propertyName',
    defaultValue: 'propertyName',
  });

  model.value = modelObject;
  labels.value = labelObject;
  model.value['idNumberType'] = 'IdNumber'; //defaulting to SA ID
  labels.value['idNumber'] = 'jpc-reg-idNumber';
  assignModelError({
    propertyName: 'sourceOfIncome',
    page: 2,
    error: `formErrors.sourceOfIncome`,
  });

  // removing both pseudo input elements to prevent user manipulation
  //setTimeout to allow google chrome to autofill
  setTimeout(() => {
    const elements = document.getElementsByName('removePseudo');
    [...elements].forEach((el) => {
      el.remove();
    });

    const primaryMobileNumberInput = document.getElementById(
      'primaryMobileNumber'
    );
    primaryMobileNumberInput.setAttribute('inputmode', 'numeric');
    primaryMobileNumberInput.setAttribute('type', 'tel');
    primaryMobileNumberInput.setAttribute('pattern', '[0-9]*');
  }, 500);
});

const returnComponent = (fieldControlId) =>
  templateFieldControl.find(({ TemplateFieldControlId }) => {
    return TemplateFieldControlId === fieldControlId;
  });

const validateField = ({ regExpression, propertyName, page, attributes }) => {
  if (propertyName === 'FirstName') {
    const modelVal = model.value['FirstName'];
    const regex = new RegExp("^[a-zA-Z][a-zA-Z\-\.'\s]{1,20}$");
    const isValid = regex.test(modelVal);
    if (!isValid) {
      assignModelError({
        propertyName,
        page,
        error: `formErrors.${propertyName}`,
      });
    }
  }

  if (propertyName === 'LastName') {
    const modelVal = model.value['LastName'];
    const regex = new RegExp("^[a-zA-Z][a-zA-Z\-\.'\s]{1,20}$");
    const isValid = regex.test(modelVal);
    if (!isValid) {
      assignModelError({
        propertyName,
        page,
        error: `formErrors.${propertyName}`,
      });
    }
  }

  if (propertyName === 'email') {
    const modelVal = model.value['email'];

    const regex = new RegExp(regExpression);
    const isValid = regex.test(modelVal);

    if (!isValid) {
      assignModelError({
        propertyName,
        page,
        error: `formErrors.${propertyName}`,
      });
    }
  }

  if (propertyName === 'idNumberType') {
    const modelVal = model.value['idNumberType'];
    if (modelVal === 'Passport') {
      bdayLock.value = false;
      labels.value['idNumber'] = 'jpc-reg-Passport';
      validatePassport('idNumber', page);
    } else {
      validateIdCard('idNumber', page, regExpression);
      labels.value['idNumber'] = 'jpc-reg-idNumber';
    }
  }

  if (propertyName === 'idNumber') {
    if (model.value['idNumberType'] !== 'Passport') {
      validateIdCard(propertyName, page, regExpression);
    } else {
      validatePassport(propertyName, page);
    }
  }

  try {
    let val = model.value[propertyName];
    if (propertyName === 'dateOfBirth') {
      val = dateOfBirth.value;
      regExpression =
        '^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2}(?:.[0-9]*)?)((-([0-9]{2}):([0-9]{2})|Z)?)$';
      const date = dayjs();
      const difference = date.diff(val, 'year');
      if (difference < 18)
        return assignModelError({
          propertyName,
          page,
          error: `formErrors.${propertyName}-underage`,
        });
    }

    const rege = new RegExp(regExpression);

    const isValid = rege.test(val);
    const isRequired = attributes?.required === 'true';

    // prevent receivePromoInfo from affecting progress bar
    if (
      isValid &&
      propertyName !== 'receivePromotionalInformation' &&
      propertyName !== 'idNumber' &&
      propertyName !== 'idNumberType'
    ) {
      assignModelValid({ propertyName, page });
    }

    if (
      isRequired &&
      (!isValid || !val) &&
      propertyName !== 'email' &&
      propertyName !== 'idNumber'
    ) {
      assignModelError({
        propertyName,
        page,
        error: `formErrors.${propertyName}`,
      });
    }
  } catch (e) {
    console.error(e);
    assignModelError({
      propertyName,
      page,
      error: `formErrors.${propertyName}`,
    });
  }
};

function validateIdCard(propertyName, page, regExpressionDate) {
  const modelVal = String(model.value['idNumber']);
  const regExpression = '^\\d{13}$';
  const regex = new RegExp(regExpression);
  const validId = regex.test(modelVal);

  if (validId) {
    assignModelValid({ propertyName, page });
    const regExpression =
      modelVal.substring(0, 2) % 4 !== 0 ? dobRegex : leapYearDobRegex;
    const regex = new RegExp(regExpression);
    const validDob = regex.test(modelVal);

    if (validDob) {
      //lock DOB
      bdayLock.value = true;
      //generate DOB
      const { year, month, day } =
        $registrationService.generateDateOfBirthFromID(modelVal);
      const formattedDate = `${year}-${month}-${day}`;
      model.value['dateOfBirth'] = new Date(formattedDate);
      const date = dayjs();
      const difference = date.diff(formattedDate, 'year');
      dateFormatandValidate({
        regExpressionDate,
        propertyName: 'dateOfBirth',
        page,
      });
      if (difference < 18) {
        assignModelError({
          propertyName,
          page,
          error: `formErrors.${propertyName}-underage`,
        });
      } else {
        assignModelValid({ propertyName, page });
      }
    } else {
      //unlock DOB
      bdayLock.value = false;
      //assignModelError invalid DOB

      assignModelError({
        propertyName,
        page,
        error: `formErrors.${propertyName}`,
      });
    }
  } else {
    //Invalid ID, too short or not numbers
    bdayLock.value = false;
    //assignModelError invalid ID
    assignModelError({
      propertyName,
      page,
      error: `formErrors.${propertyName}`,
    });
  }
}

function validatePassport(propertyName, page) {
  const modelVal = model.value['idNumber'];
  const regExpression = '^[a-zA-Z0-9]{3,15}$';
  const regex = new RegExp(regExpression);
  const isValid = ref(false);

  isValid.value = modelVal === null ? false : regex.test(modelVal); //checking if passport val is empty

  if (isValid.value) {
    assignModelValid({ propertyName, page });
  } else {
    assignModelError({
      propertyName,
      page,
      error: `formErrors.${labels.value[propertyName]}`,
    });
  }
}

const dateFormatandValidate = ({ regExpression, propertyName, page }) => {
  dayjs.extend(customParseFormat);
  const date = new Date(model.value[propertyName]).toISOString();
  dateOfBirth.value = date;
  validateField({ regExpression, propertyName, page });
};
const validityClasses = computed(() => {
  return (propertyName) => {
    if (!!modelErrors[propertyName]) return 'border-1 border-red-800';
    if (!!modelValidity[propertyName]) return 'border-1 border-green-800';
    if (!!modelErrors[propertyName] && !!modelValidity[propertyName]) return '';
  };
});
const assignModelError = ({ propertyName, page, error }) => {
  revokeModelValidity({ propertyName });
  modelErrors.value = {
    ...modelErrors.value,
    [propertyName]: {
      page,
      error,
    },
  };
};
const assignModelValid = ({ propertyName, page }) => {
  revokeModelError({ propertyName });
  modelValidity.value = {
    ...modelValidity.value,
    [propertyName]: {
      page,
    },
  };
};
const revokeModelError = ({ propertyName }) => {
  const modelErrorsReference = { ...modelErrors.value };
  delete modelErrorsReference[propertyName];
  modelErrors.value = modelErrorsReference;
};
const revokeModelValidity = ({ propertyName }) => {
  const modelValidityReference = { ...modelValidity.value };
  delete modelValidityReference[propertyName];
  modelValidity.value = modelValidityReference;
};

//Focus mobile no input when user already exists error occurs
function focusMobileNo() {
  setTimeout(() => {
    document.getElementById('primaryMobileNumber').focus();
  }, 250);
}

async function register() {
  const additionalProps = {
    signUpCode: '',
    referralCode: '',
    countryCode: site.region,
    currencyCode: site.getCurrencyCode,
    dialingCode: site.getDialingCode,
    brandId: 'D76A0E62-B728-4A28-8134-C57A1A003199',
    language: 'English',
    gender: 'Male',
    languageCode: 'EN',
    cultureCode: site.region,
    communicationChannel: 'Web',
    btagcookie: $storageService.getCookie({ key: 'BTAGCOOKIE' }),
    bannerTag: $storageService.getCookie({ key: 'BTAGCOOKIE' }),
    st: $storageService.getCookie({ key: 'ST' }),
    mt: $storageService.getCookie({ key: 'MT' }),
    sessionToken: $storageService.getCookie({ key: 'ST' }),
    masterToken: $storageService.getCookie({ key: 'MT' }),
    originalQueryString: $storageService.getCookie({
      key: 'OriginalQueryString',
    }),
    username: prepareMobileNumber(model.value.primaryMobileNumber),
    primaryMobileNumber: prepareMobileNumber(model.value.primaryMobileNumber),
    idNumberType: model.value.idNumberType.replaceAll(' ', ''),
  };

  formError.value = '';
  formLoading.value = true;

  // send GA tracking method for register request init
  await trackingMethods.register(model.value['primaryMobileNumber']);

  await $authService
    .register({ ...model.value, ...additionalProps })
    .then((data) => {
      if (data?.isSuccessful) {
        //Successful reg
        trackingMethods.registerComplete(model.value['primaryMobileNumber']);

        if (model.value['idNumberType'] === 'Passport') {
          //if passport go to welcome offer
          authStore.setPartialPostReg(true);
          site.activateModal('registrationWelcomeOffer');
        } else {
          if (!data.data.complianceResponse.isValidId) {
            //minor or deceased
            formError.value = $t('deceased-or-minor-reg');
            trackingMethods.registerFailure('Deceased or Minor Individual Reg');
            throw 'deceased-or-minor-reg';
          } else {
            if (data.data.complianceResponse.complianceStatus > 0) {
              //auto fica'd
              authStore.setPartialPostReg(false);
              site.activateModal('registrationWelcomeOffer');
            } else {
              //call names to verify
              $complianceService.getPostRegNames().then((data) => {
                if (data.isSuccessful) {
                  site.activateModal('registrationVerifyName'); //cpb returned names list to verify
                } else {
                  //cpb returned null nav to welcome offer
                  authStore.setPartialPostReg(true);
                  site.activateModal('registrationWelcomeOffer');
                }
              });
            }
          }
        }
      } else {
        //unsuccessful reg
        const error = data?.error;
        formError.value = error.message;
        if (error.code === 132002) {
          activePage.value--;
          focusMobileNo();
          assignModelError({
            propertyName: 'primaryMobileNumber',
            page: 0,
            error: $t('number-in-use'),
          });
        }
        trackingMethods.registerFailure(data.error.message);
      }
    })
    .catch((e) => {
      formError.value = $t('registration-failed');
      console.error(e);
      if (e === 'deceased-or-minor-reg') {
        formError.value = $t(e);
        auth.logout();
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
}

// Clear IDNumber when switching IDNumberType
watch(
  () => model.value['idNumberType'],
  (oldIdType, newIdType) => {
    if (oldIdType !== newIdType) {
      model.value['idNumber'] = '';
    }
  }
);

function nextPage() {
  activePage.value++;
  // Send GA tracking response for personal details page completed
  trackingMethods.registerPersonalComplete(model.value['username']);
}

const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

const debounceVali = debounce(validateField, 100);
</script>
<template>
  <div>
    <form v-if="template.length">
      <!-- Creating two pseudo input elements for google chrome to autofill  -->
      <input
        type="email"
        style="opacity: 0; position: absolute"
        id="username"
        name="removePseudo"
      />
      <input
        type="password"
        style="opacity: 0; position: absolute"
        name="removePseudo"
      />
      <div class="bg-light-400 dark:bg-dark-900 border-round-md mx-3 mt-2.5">
        <ProgressBar :value="progressBar" :show-value="false" />
      </div>
      <div
        v-for="({ sectionName, templateFields }, index) in template"
        :key="index"
        class="m-3 mt-2"
        :class="activePage === index ? '' : 'hidden'"
      >
        <div
          v-for="{
            templateFieldControlId,
            id,
            propertyName,
            regExpression,
            items,
            templateFieldAttributes,
            page,
          } in mergeSort(templateFields, 'sortIndex')"
          :key="id"
        >
          <label
            v-if="
              returnComponent(templateFieldControlId)?.AdditionalInfo
                ?.externalLabel
            "
            class="text-black dark:text-white text-xs font-normal"
            v-html="$t(`label.${propertyName}`)"
          ></label>
          <component
            v-model="model[propertyName]"
            v-bind="{ ...returnComponent(templateFieldControlId)?.Bindings }"
            :inputProps="{ autocomplete: 'off' }"
            :is="returnComponent(templateFieldControlId)?.Component"
            :key="id"
            :id="id"
            :options="translateItems(items)"
            optionLabel="key"
            optionValue="value"
            :label="$t(labels[propertyName])"
            :placeholder="$t(`placeholder.${labels[propertyName]}`)"
            class="w-full my-1"
            :class="validityClasses(propertyName)"
            :form-key="propertyName"
            :false-value="false"
            :disabled="
              returnComponent(templateFieldControlId)?.Bindings?.disabled !=
              null
                ? bdayLock
                : false
            "
            :icon="
              returnComponent(templateFieldControlId)?.Bindings?.disabled !=
              null
                ? calendarIcon
                : ''
            "
            @input="
              debounceVali({
                regExpression,
                propertyName,
                page,
                attributes: templateFieldAttributes,
              })
            "
            @change="
              ($event) =>
                validateField({
                  regExpression,
                  propertyName,
                  page,
                  attributes: templateFieldAttributes,
                })
            "
            @date-select="
              ($event) =>
                dateFormatandValidate({
                  regExpression,
                  propertyName,
                  page,
                  attributes: templateFieldAttributes,
                })
            "
            @blur="
              ($event) =>
                validateField({
                  regExpression,
                  propertyName,
                  page,
                  attributes: templateFieldAttributes,
                })
            "
          />
          <label
            v-if="
              returnComponent(templateFieldControlId)?.AdditionalInfo
                ?.suffixLabel
            "
            class="text-black dark:text-white text-xs font-normal"
            v-html="$t(propertyName)"
          ></label>
          <small
            class="p-error text-xs ml-1"
            v-if="modelErrors[propertyName]?.error"
          >
            {{ $t(modelErrors[propertyName]?.error) }}
          </small>
        </div>
      </div>
      <GenericError v-if="formError" class="mb-2" state="danger">
        {{ formError }}
      </GenericError>
      <div class="p-3 bg-light-200 dark:bg-dark-900 login-action">
        <div
          v-if="isFirstPage"
          class="border-round-md flex justify-content-between align-items-center max-w-full px-2 py-0 bg-light-50 dark:bg-dark-800 text-xs mb-2"
        >
          <p class="my-2.5 dark:text-white text-black">
            {{ $t('already-have-account') }}
          </p>
          <div class="flex flex-row flex-nowrap align-items-center">
            <p
              class="my-2.5 mr-1 dark:text-primary-blue-100 text-primary-blue-400 text-xs flex flex-row cursor-pointer"
              @click="site.activateModal('login')"
            >
              {{ $t('login') }}
            </p>
            <i
              class="pi pi-chevron-right text-xs dark:text-primary-blue-100 text-primary-blue-400 font-light"
            />
          </div>
        </div>
        <div v-if="!isLastPage" class="w-full">
          <Button
            :disabled="!isPageValid"
            class="text-white text-base font-bold text-center justify-content-center align-items-center min-w-full px-2 mt-1"
            @click="nextPage()"
          >
            {{ $t('next') }}
          </Button>
        </div>
        <div
          v-if="!isFirstPage"
          class="bg-light-200 dark:bg-dark-900 grid-layout grid-cols-2 gap-2"
        >
          <Button
            class="w-full"
            group="secondary"
            @click="activePage--"
            :label="$t(`previous`)"
          />
          <Button
            :disabled="!isPageValid"
            :key="isPageValid"
            class="w-full"
            @click="register()"
            :label="$t(`jpc-register`)"
          />
        </div>
      </div>
    </form>
    <GenericLoader v-else />
    <GenericLoader v-if="formLoading" container />
  </div>
</template>

<style lang="scss">
.p-progressbar {
  height: 5px !important;
  background: #dae0ed;
  &-value {
    background-image: linear-gradient(
      88deg,
      #2530ac 0%,
      #3879fb 100%
    ) !important;
    height: 5px !important;
  }
}

.dark {
  .p-progressbar {
    background: #121417;
  }
}
</style>
