<script setup>
import Button from '~/components/design-system/button.vue';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import GenericError from '~/components/user-interface/generic-error.vue';
import PrefixInput from '~/components/design-system/form/prefixInput.vue';
import { trackingMethods } from '~/plugins/analytics.client';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';

const site = useSiteStore();
const { $toastService } = useServices();

const loginData = ref({ username: '', password: '', countryCode: site.region });
const fieldErrors = ref({ username: '', password: '' });
const activeLoader = ref(false);
const { $t } = useNuxtApp();
const { $authService, $walletService } = useServices();
const route = useRoute();
const errorCode = ref('');
const fetchFavoriteGames = useGetFavorites;
const errorsObject = ref({
  isEmpty: 'field-cannot-be-empty',
  isEmailValid: 'provide-valid-email',
  isMobileValid: 'provide-valid-mobile-no',
  isPasswordValid: 'provide-valid-password',
  emptyString: '',
});

const router = useRouter();
const prepareMobileNumber = usePrepareMobileNumber;

const login = () => {
  if (checkEmptyFields()) {
    displayMessagesForEmptyFields();
  } else {
    displayEmptyStringMessageForFailedLogin();
    setActiveLoaderToTrue();
    doTrackLogin();
    doLogin();
  }
};

const checkEmptyFields = () => {
  return loginData.value.username === '' || loginData.value.password === '';
};

const displayMessagesForEmptyFields = () => {
  if (loginData.value.username === '')
    fieldErrors.value.username = displayEmptyFieldMessage();
  else displayEmptyStringMessageForUsername();

  if (loginData.value.password === '')
    fieldErrors.value.password = displayEmptyFieldMessage();
  else displayEmptyStringMessageForPassword();
};

const displayEmptyFieldMessage = () => {
  return errorsObject.value.isEmpty;
};

const displayEmptyStringMessage = () => {
  return errorsObject.value.emptyString;
};

const displayEmptyStringMessageForUsername = () => {
  return (fieldErrors.value.username = displayEmptyStringMessage());
};

const displayInvalidMobileMessage = () => {
  return (fieldErrors.value.username = errorsObject.isMobileValid);
};

const displayEmptyStringMessageForPassword = () => {
  return (fieldErrors.value.password = displayEmptyStringMessage());
};

const displayEmptyStringMessageForFailedLogin = () => {
  displayEmptyStringMessageForUsername();
  displayEmptyStringMessageForPassword();
  displayInvalidMobileMessage();
};

const setActiveLoaderToFalse = () => {
  return (activeLoader.value = false);
};

const setActiveLoaderToTrue = () => {
  return (activeLoader.value = true);
};

const doTrackLogin = () => {
  // send GA tracking response for login init
  trackingMethods.login(loginData.value.username);
};

const doTrackLoginFailure = (error) => {
  // send GA tracking response for login fail
  trackingMethods.loginFailure(loginData.value.username, error.message);
};

const routeToCurrentGame = () => {
  if (route.query.redirect) {
    const splitUrl = route.query.redirect.split('?');
    const queryparams = new URLSearchParams(splitUrl[1]);
    router.push({ path: splitUrl[0], query: Object.fromEntries(queryparams) });
  }
};

const doLogin = async () => {
  const specialCharCheckRegex = /[\.\-]+/;
  if (specialCharCheckRegex.test(loginData.value.username)) {
    fieldErrors.value.username = 'login-contains-invalid-chars';
    setActiveLoaderToFalse();
    return;
  }

  const mobileNumberRegex = /[1-9]/;
  const username = mobileNumberRegex.test(loginData.value.username)
    ? prepareMobileNumber(loginData.value.username)
    : loginData.value.username;
  const request = { ...loginData.value, username };
  await $authService
    .login(request)
    .then(async (data) => {
      if (!data.isSuccessful) {
        checkErrors(data.error);
        doTrackLoginFailure(data.error);
      } else {
        await $walletService.fetchBalances();
      }
      setActiveLoaderToFalse();
      routeToCurrentGame();
      return data;
    })
    .finally(async () => {
      await fetchFavoriteGames();
      if (site.getFeatureFlags.get('website.toastmessages')) {
        await $toastService.toastService.fetchToasts();
      }
    })
    .catch((e) => {
      errorCode.value = 'something-went-wrong';
      setActiveLoaderToFalse();
    });
};

const checkErrors = (error) => {
  console.error(error);
  if (!!error) {
    errorCode.value = error.code;
  }
};

const allowLogin = computed(() => {
  if (
    loginData.value.username.length > 1 &&
    loginData.value.password.length > 7
  )
    return true;
  return false;
});

const display = computed(() => activeLoader.value);

const forgotPassword = () => {
  // Password reset view for logged out user
  site.activateModal('passwordResetStepOne');
};

function validateInput() {
  const specialCharCheckRegex = /^0-9/;
  if (!specialCharCheckRegex.test(loginData.value.username)) {
    loginData.value.username = loginData.value.username.replaceAll(
      /[^0-9]/g,
      ''
    );
  }

  checkEmptyFields();
}
</script>
<template>
  <div
    class="login-container relative flex flex-column h-max-content"
    @keyup.enter="login()"
  >
    <GenericLoader v-if="display" :key="activeLoader" container />
    <form class="relative">
      <div class="flex flex-column m-3">
        <PrefixInput
          class="input--prefix w-full"
          :class="fieldErrors.username ? 'border-1 border-red-800' : ''"
          form-key="username"
          v-model="loginData.username"
          :label="$t('username')"
          :prefixCopy="site.getDialingCode"
          :prefixClass="fieldErrors.username ? 'border-1 border-red-800' : ''"
          pattern="[0-9]*"
          input-mode="numeric"
          @input="validateInput()"
        />
        <small class="p-error text-xs">{{ $t(fieldErrors.username) }}</small>
        <div class="p-inputgroup w-auto">
          <span class="p-float-label">
            <Password
              id="password"
              v-model="loginData.password"
              toggleMask
              :feedback="false"
              :inputClass="
                fieldErrors.password ? 'border-1 border-red-800' : ''
              "
              :pt="{
                input: ({ props }) => ({
                  class:
                    props.modelValue.length > 1
                      ? 'border-1 border-red-800 border-round-md bg-light-50'
                      : 'bg-dark-500',
                }),
              }"
            />
            <label for="password" class="dark:text-white input-label">
              {{ $t('Password') }}
            </label>
          </span>
        </div>
        <small class="p-error text-xs">{{ $t(fieldErrors.password) }}</small>
      </div>
      <GenericError
        class="mb-3"
        v-if="errorCode & (errorCode !== 133104)"
        state="danger"
      >
        {{ $t(`jpc-login-errors-${errorCode}`) }}
      </GenericError>
      <!-- Redirect user to password reset -->
      <GenericError class="mb-3" state="danger" v-if="errorCode === 133104">
        <span>{{ $t('password-has-expired-please') }}</span>
        <span
          class="cursor-pointer underline mx-1 lowercase dark:text-primary-blue-500 text-primary-blue-400"
          @click="site.activateModal('passwordResetStepOne')"
        >
          {{ $t('reset') }}
        </span>
        <span>{{ $t('it') }}</span>
      </GenericError>
    </form>
    <div class="p-3 bg-light-200 dark:bg-dark-900 login-action">
      <div
        class="border-round-md flex justify-content-between align-items-center max-w-full px-2 mb-2 bg-light-50 dark:bg-dark-800 text-xs"
      >
        <p class="my-2.5 dark:text-white text-black">
          {{ $t('dont-have-account') }}
        </p>
        <div class="flex flex-row flex-nowrap align-items-center">
          <p
            class="my-2.5 mr-1 dark:text-primary-blue-500 text-primary-blue-400 text-xs flex flex-row cursor-pointer"
            @click="site.activateModal('register')"
          >
            {{ $t('jpc-register') }}
          </p>
          <i
            class="pi pi-chevron-right text-xs dark:text-primary-blue-500 text-primary-blue-400 font-light"
          />
        </div>
      </div>
      <div class="min-w-full mb-2">
        <Button
          class="text-white uppercase text-base font-bold text-center justify-content-center align-items-center min-w-full px-2 py-2 mt-1 mb-1"
          :group="allowLogin ? 'primary' : 'secondary'"
          @click="login()"
        >
          {{ $t('login') }}
        </Button>
      </div>
      <p
        class="dark:text-primary-blue-500 text-primary-blue-400 text-xs text-center cursor-pointer my-0"
        @click="forgotPassword()"
      >
        {{ $t('forgot-password') }}
      </p>
    </div>
  </div>
</template>
