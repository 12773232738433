<script setup lang="ts">
type statusTypes = 'pending' | 'incomplete' | 'complete';
const background: globalThis.Ref<string> = ref('');
interface IIconProps {
  status: statusTypes;
}

const props = withDefaults(defineProps<IIconProps>(), {
  status: 'incomplete',
});

function determineIcon() {
  background.value =
    props.status === 'complete'
      ? 'primary-blue-400'
      : props.status === 'incomplete'
      ? 'red-600'
      : 'primary-gold-500';
}

onMounted(() => {
  determineIcon();
});
</script>

<template>
  <div
    class="relative flex justify-content-center align-items-center pl-1 sm:pl-2"
  >
    <div
      class="inner-dot absolute w-2 h-2 border-circle"
      :class="`bg-${background}`"
    />
    <div
      class="outer-ring w-4 h-4 border-circle opacity-50"
      :class="`bg-${background}`"
    />
  </div>
</template>

<style scoped></style>
