<script setup lang="ts">
import type { InputProps } from '~/interfaces/form/textInputInterface';

const props = withDefaults(defineProps<InputProps>(), {
  class: '',
  prefixCopy: '',
  suffixCopy: '',
  prefixPath: '',
  suffixPath: '',
  formKey: '',
  label: '',
  type: 'text',
  modelValue: '',
  prefix: '',
  prefixClass: '',
  suffixClass: '',
  disabled: false,
  placeholder: '',
  focus: false,
  inputMode: 'text',
});

const emit = defineEmits(['update:modelValue', 'blur']);
const input = ref();

function generatePrefix() {
  if (props.prefixCopy) {
    return props.prefixCopy;
  } else if (props.prefixPath) {
    return props.prefixPath;
  }
  if (props.suffixCopy) {
    return props.suffixCopy;
  } else if (props.suffixPath) {
    return props.suffixPath;
  }
  return;
}

onMounted(() => {
  generatePrefix();
});
</script>

<template>
  <span class="p-float-label">
    <div class="p-inputgroup prefix">
      <span
        v-if="props.prefixCopy || props.prefixPath"
        :class="`p-inputgroup-addon ` + props.prefixClass"
      >
        <span v-if="props.prefixCopy">{{ props.prefixCopy }}</span>
        <i v-if="props.prefixPath" :class="props.prefixPath"></i>
      </span>
      <InputText
        ref="input"
        autocomplete="off"
        :class="props.class"
        :id="props.formKey"
        :type="props.type"
        :model-value="modelValue"
        @input="emit('update:modelValue', $event.target.value)"
        @blur="$emit('blur')"
        :disabled="props.disabled"
        :placeholder="props.placeholder"
        :autofocus="props.focus"
        :pattern="pattern"
        :inputmode="props.inputMode"
      />
      <slot name="primaryMobile"></slot>
      <label class="input-label" :for="props.label">{{ props.label }}</label>
    </div>
  </span>
</template>
<style>
.p-inputtext {
  caret-color: #3879fb;
}
</style>
