import Input from '~/components/design-system/form/input.vue';
import Checkbox from '~/components/design-system/form/checkbox-v2.vue';
import Password from '~/components/design-system/form/password.vue';
import Calendar from 'primevue/calendar';
import PrefixInput from '~/components/design-system/form/prefixInput.vue';
import Dropdown from 'primevue/dropdown';
const minAge = new Date();
minAge.setFullYear(minAge.getFullYear() - 18);
export const templateFieldControl = [
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200001',
    Title: 'Text Box',
    AdditionalInfo: null,
    Bindings: null,
    Type: 'text',
    Component: Input,
    templateFieldAttributes: {
      required: true,
    },
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200002',
    Title: 'Numeric Only',
    AdditionalInfo: null,
    Bindings: null,
    Type: 'number',
    Component: Input,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200003',
    Title: 'Drop Down',
    AdditionalInfo: null,
    Bindings: {
      optionLabel: (opt: any) => opt.description ?? opt,
      optionValue: (opt: any) => opt.description ?? opt,
    },
    Type: 'select',
    Component: Dropdown,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200004',
    Title: 'Radio Button',
    AdditionalInfo: null,
    Bindings: null,
    Type: 'radio',
    Component: Checkbox,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200005',
    Title: 'Radio Button List',
    AdditionalInfo: {
      suffixLabel: true,
    },
    Bindings: null,
    Type: 'radio',
    Component: Checkbox,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200006',
    Title: 'Check Box',
    AdditionalInfo: {
      suffixLabel: false,
      default: false,
    },
    Bindings: null,
    Type: 'checkbox',
    Component: Checkbox,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200007',
    Title: 'Label',
    AdditionalInfo: null,
    Bindings: null,
    Type: 'label',
    Component: Input,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200008',
    Title: 'Password',
    AdditionalInfo: null,
    Bindings: null,
    Type: 'password',
    Component: Password,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200009',
    Title: 'Date Picker',
    AdditionalInfo: {
      externalLabel: true,
    },
    Bindings: {
      disabled: false,
      maxDate: minAge,
      dateFormat: 'dd-mm-yy',
      id: 'calendar',
      appendTo: '.p-calendar',
      showIcon: true,
    },
    Type: 'date',
    Component: Calendar,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200010',
    Title: 'Date Time Picker',
    AdditionalInfo: null,
    Bindings: null,
    Type: 'datetime-local',
    Component: Input,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200011',
    Title: 'Prefix Input',
    AdditionalInfo: null,
    Bindings: { 'prefix-copy': '+27' },
    Type: 'text',
    Component: PrefixInput,
  },
  {
    TemplateFieldControlId: '00000000-0000-0000-da7a-000000200014',
    Title: 'Email',
    AdditionalInfo: '',
    Type: 'text',
    Component: Input,
    templateFieldAttributes: {
      required: true,
    },
  },
];
