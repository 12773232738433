<script setup lang="ts">
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import type { DialogBreakpoints } from 'primevue/dialog';
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import Dialog from 'primevue/dialog';
import { useAccountOptions } from '~/composables/useAccountOptions';
interface IModalProps {
  title?: string;
  breakpoints?: DialogBreakpoints;
  width?: string;
  active?: boolean;
  contentType?: 'form' | 'content';
}

const authStore = useAuthStore();
const viewport = useViewport();

const { $t }: any = useNuxtApp();
const emits = defineEmits(['closeModal']);
const displayModal: globalThis.Ref<boolean> = ref(false);
const props = withDefaults(defineProps<IModalProps>(), {
  active: false,
});

const currentPage: globalThis.ComputedRef<string | string[]> = computed(() => {
  if (
    !authStore.getAccountModalPage ||
    (!authStore.getAccountModalPage && !authStore.getAccountModalSubPage)
  )
    return;
  return authStore.getAccountModalPage || useAccountOptions()[0].id;
});

const component = computed(() => {
  return (
    useAccountOptions().find(({ id }) => id === currentPage.value)?.component ||
    useAccountOptions()[0].component
  );
});
const openModal = () => {
  if (props.active) {
    displayModal.value = true;
  }
};
const closeModal = () => {
  displayModal.value = false;
  unlockBodyScroll();
};
</script>

<template>
  <div>
    <div @click="openModal">
      <slot name="modal-activator"></slot>
    </div>
    <Dialog
      :modal="true"
      :closable="false"
      :draggable="false"
      :breakpoints="props.breakpoints"
      :visible="props.active"
      class="dark:bg-dark-900 overflow-visible container user-acc-modal"
      :show-header="viewport.isGreaterOrEquals('tablet')"
      @show="lockBodyScroll()"
      @hide="unlockBodyScroll()"
    >
      <template #header>
        <div
          class="flex flex-row justify-content-between w-full align-content-center"
        >
          <h2 class="h-fit">
            {{ $t('account-options') }} ({{
              authStore.currentUser.primaryMobileNumber
            }})
          </h2>
          <Button
            @click="$emit('closeModal'), closeModal()"
            group="tertiary-icon"
            icon="pi pi-times"
            size="medium"
          />
        </div>
      </template>
      <UserAccountLayout>
        <!--        <slot name="modal-content" />-->
        <component :is="component" :key="currentPage" />
      </UserAccountLayout>

      <template #footer>
        <slot name="footer-content"></slot>
      </template>
    </Dialog>
  </div>
</template>
<style lang="scss">
.p-dialog.user-acc-modal .p-dialog-header {
  border-bottom: 1px solid;
}

.user-acc-modal {
  max-height: 85vh;
  @media screen and (max-width: 768px) {
    max-height: 95dvh;
  }
}
</style>
