<script setup>
import Button from '~/components/design-system/button.vue';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import GenericError from '~/components/user-interface/generic-error.vue';
import { trackingMethods } from '~/plugins/analytics.client';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';

const { $t } = useNuxtApp();
const site = useSiteStore();
const route = useRoute();
const router = useRouter();
const { $authService, $walletService, $toastService } = useServices();

const fetchFavoriteGames = useGetFavorites;
const prepareMobileNumber = usePrepareMobileNumber;

const activeLoader = ref(false);
const errorCode = ref('');
const form = ref();

async function login() {
  activeLoader.value = true;
  // send GA tracking response for login init
  trackingMethods.login(form?.value?.data['username']);
  const username = prepareMobileNumber(form?.value?.data['username']);
  const request = {
    username: username,
    password: form?.value?.data['password'],
    countryCode: site.region,
  };
  await $authService
    .login(request)
    .then(async (data) => {
      if (!data?.isSuccessful) {
        checkErrors(data.error);
        // send GA tracking response for login failure
        trackingMethods.loginFailure(username, data.error.message);
      } else {
        await $walletService.fetchBalances();
        routeToCurrentGame();
      }
      setActiveLoaderToFalse();

      return data;
    })
    .finally(async () => {
      await fetchFavoriteGames();
      if (site.getFeatureFlags.get('website.toastmessages')) {
        await $toastService.toastService.fetchToasts();
      }
      trackingMethods.loginComplete(username);
    })
    .catch((e) => {
      errorCode.value = $t('something-went-wrong');
      setActiveLoaderToFalse();
    });
}

const setActiveLoaderToFalse = () => {
  return (activeLoader.value = false);
};

const routeToCurrentGame = () => {
  if (route.query.redirect) {
    const splitUrl = route.query.redirect.split('?');
    const queryparams = new URLSearchParams(splitUrl[1]);
    router.push({ path: splitUrl[0], query: Object.fromEntries(queryparams) });
  }
};

const checkErrors = (error) => {
  console.error(error);
  if (!!error) {
    errorCode.value = error.code;
  }
};

const display = computed(() => activeLoader.value);

const forgotPassword = () => {
  // Password reset view for logged out user
  site.activateModal('passwordResetStepOne');
};
</script>
<template>
  <div
    class="login-container relative flex flex-column h-max-content"
    @keyup.enter="login()"
  >
    <GenericLoader v-if="display" :key="activeLoader" container />
    <Vueform
      ref="form"
      :float-placeholders="true"
      :endpoint="false"
      class="mx-3 mt-3"
    >
      <template #empty>
        <TextElement
          name="username"
          input-type="number"
          :attrs="{ inputmode: 'numeric' }"
          :placeholder="$t('placeholder.primaryMobileNumber')"
          :floating="$t('primaryMobileNumber')"
          :rules="[
            'required',
            'regex:/^[6-8][0-9]{8}$|^0[6-8][0-9]{8}$|^27[6-8][0-9]{8}$/g',
          ]"
          :messages="{ regex: $t('formErrors.primaryMobileNumber') }"
          class="mb-2"
        >
          <template #addon-before>
            <div
              class="vf-mobile-prefix px-2 dark:bg-dark-900 bg-light-200 b-0 b-right-1 b-light-400 dark:b-dark-600"
            >
              {{ site.getDialingCode }}
            </div>
          </template>
        </TextElement>
        <VueformPasswordElement
          name="password"
          :rules="[
            'required',
            'regex:/^(?!.*\\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*\\W)(?:.{8,20})$/',
          ]"
          :messages="{ regex: $t('formErrors.password') }"
          :displayFeedback="false"
        />
      </template>
    </Vueform>
    <GenericError class="mb-3" v-if="errorCode" state="danger">
      <div v-html="$t(`jpc-login-errors-${errorCode}`)" class="flex" />
    </GenericError>
    <div class="p-3 bg-light-200 dark:bg-dark-900 login-action">
      <div
        class="border-round-md flex justify-content-between align-items-center max-w-full px-2 mb-2 bg-light-50 dark:bg-dark-800 text-xs"
      >
        <p class="my-2.5 dark:text-white text-black">
          {{ $t('dont-have-account') }}
        </p>
        <div class="flex flex-row flex-nowrap align-items-center">
          <p
            class="my-2.5 mr-1 dark:text-primary-blue-500 text-primary-blue-400 text-xs flex flex-row cursor-pointer"
            @click="site.activateModal('register')"
          >
            {{ $t('jpc-register') }}
          </p>
          <i
            class="pi pi-chevron-right text-xs dark:text-primary-blue-500 text-primary-blue-400 font-light"
          />
        </div>
      </div>
      <div class="min-w-full mb-2">
        <Button
          :disabled="!(form?.validated && !form?.invalid)"
          class="text-white uppercase text-base font-bold text-center justify-content-center align-items-center min-w-full px-2 py-2 mt-1 mb-1"
          group="primary"
          @click="login()"
        >
          {{ $t('login') }}
        </Button>
      </div>
      <p
        class="dark:text-primary-blue-500 text-primary-blue-400 text-xs text-center cursor-pointer my-0"
        @click="forgotPassword()"
      >
        {{ $t('forgot-password') }}
      </p>
    </div>
  </div>
</template>
