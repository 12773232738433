<script setup lang="ts">
import { type IInputInterface } from '~~/interfaces/form/checkboxInterface';
import Checkbox from 'primevue/checkbox';
const props = withDefaults(defineProps<IInputInterface>(), {
  label: '',
  formKey: 'checkbox',
  modelValue: false,
  disabled: false,
  binary: true,
  readonly: false,
});

const { $t } = useNuxtApp();

const emit = defineEmits(['update:modelValue', 'input']);
const updateValue = ({ event }) => {
  emit('update:modelValue', event);
  emit('input', event);
};

const value = ref(false);

onMounted(() => {
  emit('update:modelValue', false);
});
</script>

<template>
  <div class="field-checkbox">
    <Checkbox
      :value="false"
      v-model="value"
      :inputId="props.formKey"
      :binary="true"
      @input="updateValue({ event: $event })"
    />
    <label :for="props.formKey" v-html="$t(props.label)" />
  </div>
</template>

<style lang="scss"></style>
