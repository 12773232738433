<script setup>
import {
  TellerIcon,
  HistoryIcon,
  LogoutIcon,
  GearIcon,
  StatusIcon,
} from '~/components/design-system/icons';
import Button from '~/components/design-system/button.vue';
import List from '~/components/design-system/list/index.vue';
import ListItem from '~/components/design-system/list/list-item.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useAccountOptions } from '~/composables/useAccountOptions';
const { $t } = useNuxtApp();
const router = useRouter();
const route = useRoute();
const auth = useAuthStore();
const breakpoint = useBreakpoint();
const selectedOption = ref('');
const placeholder = ref('');
const sideBar = [
  { id: 'teller', key: 'teller', route: '?account=teller', icon: TellerIcon },
  {
    id: 'history',
    key: 'history',
    route: '?account=history',
    icon: HistoryIcon,
  },
  {
    id: 'settings',
    key: 'account-settings',
    route: '?account=settings',
    icon: GearIcon,
  },
  { id: 'logout', key: 'logout', route: '?account=logout', icon: LogoutIcon },
];
function navigateMobile(val) {
  auth.setAccountModalPage(val.value);
}

const state = reactive({
  renderSelector: breakpoint.mdOrLess,
});

const activeRoute = (routeOption) => auth.getAccountModalPage === routeOption;

onMounted(() => {
  //on load get correct dropdown category
  const active = sideBar.find((el) => {
    return activeRoute(el.route);
  });
  placeholder.value = active?.key ?? 'teller'; //if null default to teller
});

function toggleModal() {
  auth.toggleAccountModal(false);
  auth.setAccountModalPage(null);
}

const exclusionKeys = [
  'password-management-success',
  'transaction-summary-detail',
];

const filteredAccountOptions = computed(() =>
  useAccountOptions()
    .filter((uao) => !exclusionKeys.includes(uao.key) && uao.active)
    .map((uao) => {
      return { ...uao, key: $t(uao.key) };
    })
);
</script>
<template>
  <div v-if="!state.renderSelector" class="p-3 sticky top-0">
    <List>
      <ListItem
        v-for="{ id, key, route, icon } in filteredAccountOptions"
        :key="id"
      >
        <Button
          class="flex justify-content-start align-items-center w-full mb-2 text-sm"
          :group="activeRoute(id) ? 'primary' : 'tertiary'"
          size="medium"
          @click="auth.setAccountModalPage(id)"
        >
          <component v-if="icon" :is="icon" />
          <div class="truncate">
            {{ $t(key) }}
          </div>
          <div class="status-icon">
            <StatusIcon
              v-if="!auth.getDocumentVerificationStatus && key === 'settings'"
              :status="'incomplete'"
            />
          </div>
        </Button>
      </ListItem>
    </List>
  </div>
  <div v-if="state.renderSelector" class="flex align-items-center mb-2">
    <Dropdown
      :options="filteredAccountOptions"
      optionLabel="key"
      option-value="id"
      :placeholder="$t(placeholder)"
      :model-value="auth.getAccountModalPage"
      @change="navigateMobile($event)"
      class="w-full category-filter dark:bg-dark-800 bg-light-50 mr-2"
    />
    <Button
      @click="toggleModal()"
      group="tertiary-icon"
      icon="pi pi-times"
      size="medium"
    />
  </div>
</template>

<style scoped>
.status-icon {
  position: absolute;
  z-index: 1000;
  right: 5px;
}
</style>
