<script setup lang="ts">
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/vue/outline/index.js';
import Button from '../../components/design-system/button.vue';
import HamburgerMenu from '../../components/user-interface/hamburger-menu.vue';
import ThemedImage from '~/components/design-system/themed-image.vue';
import { useSiteStore } from '~/stores/site.store';
import ParticleButton from '~/components/design-system/particle-button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useIntervalFn } from '@vueuse/core';
import RegionDebugDisplay from '~/components/tools/region-debug-display.vue';

interface INavBarProps {
  inGame?: boolean;
}

const props = withDefaults(defineProps<INavBarProps>(), {
  inGame: false,
});

const { $storageService } = useServices();

const settings = useSiteStore();
const auth = useAuthStore();
const route = useRoute();
const router = useRouter();
const currentRoute = route;
const emits = defineEmits(['overlay']);

const toggle = () => {
  dropDownActive.value = !dropDownActive.value;
  emits('overlay', dropDownActive.value);
};
const target = ref();

const dropDownActive: globalThis.Ref<boolean> = ref(false);
const logoClasses =
  'cursor-pointer bg-no-repeat md:bg-center h-10 md:h-12 lg:h-16 py-1';

const listenToBackButton = () => {
  window.addEventListener('popstate', function () {
    if (route.fullPath.includes('aviator')) {
      router.push('/');
    } else {
      window.location.reload();
    }
  });
};

const spinCount = ref<number | string>(0);
// TODO: Temporary change

onMounted(() => {
  listenToBackButton();
  useIntervalFn(() => {
    spinCount.value =
      $storageService.getLocalStorage({
        key: 'BigCityLifeWheel:SpinCount',
      }) || 0;
  }, 1000);
});

const activeLink = computed(() => (item) => {
  const { MobileApp } = item;
  const { component } = MobileApp;
  if (component === 'HomeComponent') {
    return route.path === '/';
  } else {
    return route.fullPath.startsWith(item.Path);
  }
});
</script>

<template>
  <div
    class="sticky-nav sticky top-0 bg-light-50 text-dark-700 dark:bg-dark-800 dark:text-white z-10 w-full"
  >
    <div
      class="container mx-auto flex justify-content-between align-items-center branding-wrap"
    >
      <div class="branding flex align-items-center max-w-fit overflow-hidden">
        <HamburgerMenu v-show="!inGame" />
        <ThemedImage
          :image-class="logoClasses"
          :dark="
            useImagePath(
              '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/JPCLogo_Glow.png'
            )
          "
          :light="
            useImagePath(
              '/medialibraries/content.gmgamingsystems.com/jackpotcity/branding/jpc_logo_light.svg'
            )
          "
          @click="router.push('/')"
        />
        
      </div>
      <ClientOnly>
        <LazyUserActionsNavBar class="user-actions--wrap" />
      </ClientOnly>
    </div>
    <div
      v-show="!inGame"
      class="border-y-1 border-x-none b-light-400 dark:b-dark-500 relative flex verticals-wrap md:py-1"
      :class="dropDownActive ? 'dropdown-wrap' : 'align-items-center'"
    >
      <Button
        class="md:hidden text-bold text-black dark:text-white search"
        group="link"
        icon="pi pi-search"
        icon-class="w-8"
        @click="settings.activateModal('search')"
      />
      <nav
        class="container overflow-x-auto overflow-y-hidden md:px-0 nav"
        :class="
          dropDownActive
            ? 'dropdown-active grid-cols-1 px-0'
            : 'flex flex-row align-items-center relative'
        "
        style="min-height: 42px"
        ref="target"
      >
        <NuxtLink
          v-for="item in settings.getNav({ nav: 'top' })"
          v-show="!dropDownActive"
          :key="item.id"
          :to="item.Path"
          no-prefetch
        >
          <Button
            :class="[activeLink(item) ? '' : 'dark:text-white']"
            class="mr-1 capitalize md:text-md text-sm"
            size="medium"
            :group="activeLink(item) ? 'primary' : 'link'"
          >
            {{ $t(`vertical.${item.Alias}`) }}
          </Button>
        </NuxtLink>

        <Skeleton
          v-show="settings.getNav({ nav: 'top' })?.length < 1"
          v-for="n in 6"
          :key="n"
          width="10%"
          height="2rem"
          class="mr-2"
        ></Skeleton>
        <NuxtLink
          v-show="dropDownActive"
          v-for="item in settings.getNav({ nav: 'top' })"
          class="drop-down-wrapper"
          :key="item.id"
          :to="item.Path"
          no-prefetch
          @click="($event) => toggle()"
        >
          <Button
            :class="[activeLink(item) ? '' : 'dark:text-white']"
            class="mr-2 capitalize text-sm"
            size="medium"
            :group="activeLink(item) ? 'primary' : 'link'"
          >
            {{ $t(`vertical.${item.Alias}`) }}
          </Button>
        </NuxtLink>
        <ParticleButton
          v-if="spinCount > 0 && auth.loggedIn"
          size="medium"
          group="link"
          class="dark:text-white hidden md:flex items-center text-sm"
          @click="router.push('/big-city-wheel')"
        >
          {{ $t('spin-now') }}
          <span
            class="badge absolute bg-blue-500 border-circle top-0 right-0 text-sm text-white w-4 h-4 flex align-items-center lg:justify-content-center"
          >
            <span class="mx-auto text-center w-full font-bold">
              {{ spinCount }}
            </span>
          </span>
        </ParticleButton>
      </nav>
      <Button
        class=""
        :class="
          dropDownActive
            ? 'no-shadow md:hidden bg-white dark:bg-dark-800 toggle'
            : 'md:hidden bg-white dark:bg-dark-800 toggle'
        "
        size="icon-lg"
        group="tertiary-icon"
        aria-haspopup="true"
        aria-controls="overlay_panel"
        @click="($event) => toggle()"
      >
        <ChevronDownIcon
          v-show="!dropDownActive"
          class="w-2rem text-dark-900 dark:text-white chevron"
        />
        <ChevronUpIcon
          v-show="dropDownActive"
          class="w-2rem text-dark-900 dark:text-white chevron"
        />
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sticky-nav {
  width: 100%;
}
.nav {
  transition: ease flex 150ms;
}
.link-active {
  color: red !important;
}
.verticals-wrap {
  & .drop-down-wrapper {
    padding: 5px;
    border-bottom: 1px solid #dae0ed;
    width: 100%;
    z-index: 999999;
    height: 38px;
  }
  .dark & .drop-down-wrapper {
    border-bottom: 1px solid #464f60;
  }
  & .search {
    border-right: solid 1px #dae0ed;
    border-radius: 0;
  }
  .dark & .search {
    border-right: solid 1px #464f60;
  }
  & .p-button.p-button-icon-only {
    height: 38px;
    width: 38px;
    padding: 0 8px;
  }
  & .toggle {
    height: 37px;
    box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #dae0ed;
    border-radius: 0;
    width: 38px;
    position: absolute;
    z-index: 999999;
    right: 0;
    & svg {
      height: 15px !important;
      width: auto !important;
    }
    &.no-shadow {
      box-shadow: none;
    }
  }
  .dark & .toggle {
    box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #464f60;
    &.no-shadow {
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  .nav {
    height: 38px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &.dropdown-active {
      position: absolute;
      height: unset;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      z-index: 10;
      .dark & {
        background: #1d2129;
      }
      width: calc(100vw - 38px);
      right: 0;
    }
    & .button--medium {
      height: 27px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .verticals-wrap {
    & nav {
      // override for scrolling nav on mobile
      padding-right: 4.5em !important;
      &.dropdown-active {
        padding-right: 0 !important;
      }
    }
  }
}
</style>
