<script setup lang="ts">
// destructured imports
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';
import { unlockBodyScroll } from '~/utilities/ui-utilities';
// components
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import useModalContext from '~/composables/useModalContext';
import NavigationBar from '~/components/user-interface/navigation-bar.vue';
import OfflineNotice from '~/components/user-interface/offline-notice.vue';
// route
const router = useRouter();
const route = useRoute();
// stores
const settings = useSiteStore();
const auth = useAuthStore();
// destructured composables
const { $storageService, $toastService, $promotionService } = useServices();
const { $t, $enabled } = useNuxtApp();
// composables
const runtimeConfig = useRuntimeConfig();
const viewport = useViewport();
const modalContext = useModalContext;

// refs
const overlay = ref(false);
const offline = ref<boolean>(false);
// hooks
onMounted(async () => {
  if (!import.meta.env.SSR) {
    unlockBodyScroll();

    if (navigator.userAgent.indexOf('Opera Mini') !== -1) {
      await router.push('/browser-error.html');
    }
    await $storageService.invalidateStaleIndexDb();
    addEventListener('offline', () => {
      if (!!route.query.id) {
        settings.activateModal('offline');
      } else {
        offline.value = true;
      }
    });
    addEventListener('online', () => {
      offline.value = false;
      settings.deactivateModal();
      settings.toggleSiteNotice({
        heading: $t('connection-restored-heading'),
        message: $t('connection-restored-message'),
        severity: 'success',
        enabled: true,
        timeout: 5000,
      });
    });
  }
});
// methods
function toggleOverlay() {
  overlay.value = !overlay.value;
}
const inGameMobile = computed(() => {
  return !viewport.isGreaterThan('tablet') && !!route.params?.game;
});
const modalContent = computed(() => modalContext(settings.currentModal));

function handleModalClosed() {
  if (
    !!route?.query?.id &&
    (settings.currentModal === 'login' || settings.currentModal === 'register')
  )
    router.push('/');
  settings.deactivateModal();
}

const enableNotificationPanel = computed(
  () => auth.loggedIn && !!window && !!window?.indexedDB
);
watch(
  () => auth.loggedIn,
  (value) => {
    if (value)
      nextTick(async () => {
        await $promotionService.getBigCityLifeWheelSpinCount();
      });
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
<template>
  <div>
    <div
      class="default-layout transition-colors ease-in-out duration-1000 relative w-full bg-light-200 dark:bg-dark-900"
      :class="overlay ? 'p-overflow-hidden max-h-screen' : 'min-h-screen'"
    >
      <GenericLoader v-show="settings.isFullscreenLoading" full-screen />

      <ClientOnly>
        <LazyMarketingLiveChat :key="String(auth.isLoggedIn)" />
        <DesignSystemModal
          :title="$t(modalContent.titleKey)"
          :active="settings.getModalActive"
          @close-modal="handleModalClosed()"
          :close-modal-option="modalContent?.closeModalOption"
          :header-icon="modalContent?.headerIcon"
          :header-icon-class="modalContent?.headerIconClass"
          :icon-tooltip="modalContent?.iconTooltip"
        >
          <template #modal-content>
            <component
              :is="modalContent.component"
              :key="modalContent.key"
              v-bind="modalContent"
              @close-modal="handleModalClosed()"
            />
          </template>
        </DesignSystemModal>
        <UserAccountModal
          :active="auth.accountModal"
          @close-modal="auth.toggleAccountModal(false)"
        />
        <LazyUserInterfaceToast
          v-if="
            $toastService.toastService.toasts.length &&
            $enabled('website.toastmessages') &&
            !route?.query?.id
          "
          :key="route.path"
          :toast="$toastService.toastService.nextToast"
        />
        <DesignSystemToastMessages
          :type="settings.getSiteNotice.severity"
          :heading="settings.getSiteNotice.heading"
          :message="settings.getSiteNotice.message"
          :position="settings.getSiteNotice?.position"
          :timeout="settings.getSiteNotice.timeout"
          :enabled="settings.getSiteNotice.enabled"
        />
        <OfflineNotice v-show="offline" class="body-wrap pb-6" />
      </ClientOnly>
      <NavigationBar v-show="!inGameMobile" @overlay="toggleOverlay()" />
      <div v-show="overlay" class="jpc-overlay"></div>
      <main
        v-show="!offline"
        :key="String(inGameMobile)"
        class="body-wrap pb-6"
      >
        <slot />
        <LazyUserInterfaceCookieBar v-if="$enabled('website.cookiebar')" />
      </main>
      <ClientOnly>
        <LazyUserInterfaceSiteFooter v-show="!route?.query?.id" />
        <LazyUserInterfaceMobileFooter v-show="!inGameMobile" />
        <LazyMarketingNotifications v-if="enableNotificationPanel" />
      </ClientOnly>
      
    </div>
  </div>
</template>
